@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap");

.content {
  background-color: #0c1851 !important;
  background-image: url("https://platfair.ai/wp-content/uploads/2023/07/main-background-platfair-1.png");
  background-position: top left;
  background-size: 150% !important; /* Adjust the zoom level as needed */
  background-repeat: no-repeat !important;
  color: white;
}

body {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

span.sec-branco,
.sec-colorido {
  font-size: 20px !important;
}

@media (max-width: 767px) {
  .content {
    background-size: cover !important; /* Adjust the zoom level as needed */
  }
  .video {
    height: 250px;
    padding: 10px;
  }
}

@media (max-width: 283px) {
  .card {
    width: 200px !important;
  }
}
